$grid-gutter-width: 0;
$grid-columns: 12;

$bp-mobile: 480px;
$bp-tablet: 670px;
$bp-desktop: 1075px;
$bp-wide: 1360px;

$container-sm: (738px);
$container-md: (1014px);
$container-lg: (1300px);

// https://github.com/sass-mq/sass-mq
$mq-breakpoints: (
    mobile:  $bp-mobile,
    tablet:  $bp-tablet,
    desktop: $bp-desktop,
    wide:    $bp-wide
);
// example use :
//	 	@include mq($from: mobile, $until: tablet) {
//        background: red;
//      }


$breakpoints: (
    lg:    	$bp-wide,
    md: 	$bp-desktop,
    sm:  	$bp-tablet,
    xs:  	$bp-mobile,
);



// $mobile: ($bp-mobile - 1);
// $tablet: ($bp-tablet + 1);
// $desktop: ($bp-desktop + 1);
// $min-desktop: ($tablet - 1);

// $container-sm: (738px);
// $container-md: (1020px);
// $container-lg: (1320px);



// Bootstrap
$screen-sm: $bp-tablet;
$screen-md: $bp-desktop;
$screen-lg: $bp-wide;

// fonts
$sans-serif: 'Poppins', sans-serif;
$serif: 'Crimson Text', Times, serif;
$title : 'Poppins', sans-serif;
$main : 'Crimson Text', Times, serif;

$bold: 700;
$medium: 400;
$light: 300;


$black: #333333;
$white: #ffffff;
$pink: #ff99ac;
$dark-grey : #606060;
$grey: #efefef;
$grey-light: #f7f7f7;
$black-50: #AAAAAA;
$red: #c0392b;
$red-50: #f9efed;
$purple: #8a77d2;
$colors: (
	"black": 		$black,
	"white": 		$white,
	"pink": 		$pink,
	"dark-grey": 		$dark-grey,
	"grey": 		$grey,
	"grey-light": 	$grey-light,
	"black-50": 	$black-50,
	"red": 			$red,
	"red-50": 		$red-50,
  "purple":     $purple,
);

// spaces
$spacing-xl: 	150px;
$spacing-lg: 	100px;
$spacing-md: 	50px;
$spacing-sm: 	20px;
$spacing-xs: 	10px;
$spacing-xxs: 	5px;
$spacing-min: 	3px;
$spacing-0: 	0;
$spacings: (
	"xl": $spacing-xl,
	"lg": $spacing-lg,
	"md": $spacing-md,
	"sm": $spacing-sm,
	"xs": $spacing-xs,
	"xxs": $spacing-xxs,
  "min": $spacing-min,
	"0":  $spacing-0,
)

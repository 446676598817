.t-text {
	font-size: 15px;
	line-height: 20px;
	font-family: $sans-serif;

	&--big {
		font-size: 18px;
		line-height: 24px;
	}

	&--xbig {
		font-size: 24px;
		line-height: 32px;
	}

	&--small {
		font-size: 14px;
		line-height: normal;
	}

	&--xsmall {
		font-size: 11px;
		line-height: normal;
		letter-spacing: 1px;
	}

	&--serif {
		font-family: $serif;
	}

	&--sans-serif {
		font-family: $sans-serif;
	}
}

.t-book {
    font-weight: $medium;
}
.t-light {
    font-weight: $light;
}
.t-heavy {
    font-weight: $bold;
}

.t-h1 {
	font-family: $title;
	font-size: 90px;
	line-height: 110px;
	font-weight: 900;
	letter-spacing: 2px;
	@include mq( $until: desktop ) {
		font-size: 72px;
		line-height: 86px;
	}

	@include mq( $until: tablet ) {
		font-size: 32px;
		line-height: 38px;
		letter-spacing: 1px;
	}

}

.t-h2 {
	font-family: $title;
	font-size: 56px;
	line-height: 68px;
	font-weight: 900;
	letter-spacing: 2px;

	@include mq( $until: desktop ) {
		font-size: 48px;
		line-height: 58px;
	}

	@include mq( $until: tablet ) {
		font-size: 24px;
		line-height: 28px;
		letter-spacing: 1px;
	}
}

.t-h3 {
	font-family: $title;
	font-size: 40px;
	font-weight: 900;
	letter-spacing: 2px;

	@include mq( $until: desktop ) {
		font-size: 30px;
		line-height: 5px;
		letter-spacing: 1px;
	}

	@include mq( $until: tablet ) {
		font-size: 24px;
		line-height: 5px;
	}

	&--smaller {
		font-size: 24px;
		line-height: 28px;
		font-weight: 900;
	}
}

.t-h4 {
	font-family: $main;
	font-size: 28px;
	font-weight: 400;
	letter-spacing: 1px;

	@include mq( $until: desktop ) {
		font-size: 22px;
	}

	@include mq( $until: tablet ) {
		font-size: 14px;
		line-height: 18px;
	}
}

.t-sub {
	font-family: $main;
	font-size: 12px;
	line-height: 20px;

}

.t-main {
	font-family: $main;
	font-size: 20px;
	line-height: 24px;
	letter-spacing:1px;
	font-weight: 300;


	@include mq( $until: desktop ) {
		font-size: 18px;
		line-height: 22px;
	}

	@include mq( $until: tablet ) {
		font-size:  14px;
		line-height: 18px;
	}
}

.t-shadow {
	text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
}

.js-wording {
	position: absolute;
	top: 0;
	left: 0;
}

.js-icon-src {
	position: absolute;
	top: 0;
	left: 0;
}
@mixin placeholder {
    &::-moz-placeholder {
        @content;
        opacity: 1; // See https://github.com/twbs/bootstrap/pull/11526

    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }

    &:placeholder {
        @content;
    }
}

@mixin selection {
    ::-moz-selection {
        @content;
    }

    ::selection {
        @content;
    }
}
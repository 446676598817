.popin {
	width: 50%;
	max-width: 800px;

	&-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		z-index: 150;
	}

	&-background {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $white;
		opacity: .7;
	}

	&-close {
		position: absolute;
		top: 20px;
		right: 20px;
		height: 15px;
		width: 15px;

		&:before, &:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			background-color: $black;
			width: 100%;
			height: 2px;
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}
	}
}
.checkbox, .radio {
	input {
		display: none;
	}

	label {
		padding-left: 30px;
		display: inline-block;
		position: relative;
		cursor: pointer;

		&:before {
			content: '';
			position: absolute;
			pointer-events: none;
			top: 3px;
			left: 3px;
			height: 15px;
			width: 15px;
			border: solid 2px $black;
		}

		&:after {
			content: '';
			position: absolute;
			pointer-events: none;
			top: 3 + 3px;
			left: 3 + 3px;
			height: 9px;
			width: 9px;
			background-color: $black;
			opacity: 0;
			transform: scale( .5 ) translateZ(0);
			transition: transform .3s $ease-in-out-quad, opacity .3s $ease-in-out-quad;
		}

	}
	input:checked + label {
		&:after {
			opacity: 1;
			transform: scale( 1 ) translateZ(0);
		}
	}
}

.radio {
	label {
		&:before {
			border-radius: 50%;
		}

		&:after {
			border-radius: 50%;
			top: 4 + 3px;
			left: 4 + 3px;
			height: 7px;
			width: 7px;
		}
	}
}

.input {
	position: relative;
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: solid 2px $black;
		opacity: 0;
		transition: opacity .5s $ease-in-out-quad;
		transform: translateZ(0);
	}

	&-item {
		position: relative;
		padding: 15px;
		border: solid 1px $black;
		width: 100%;

		@include placeholder {
			color: $black-50;
			font-size: inherit;
		}
	}

	&:hover:before {
		opacity: 1;
	}

	&--button {
		width: 100%;

		&:before {
			content: '';
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $grey;
			text-transform: uppercase;
			transition: transform .25s $ease-in-out-quad;
			transform: translateY(0) translateZ(0);
			opacity: 1;
			border: none;
		}

		&:hover:before {
			transform: translateY(-5px) translateZ(0);
		}

	}

	&--button &-item {
		position: relative;
		padding: 15px 0;
		padding-right: 30px;
		border: none;
		background-color: transparent;
		font-weight: $light;
		width: 100%;
	}

	&--button &-button {
		background: none;
		font-family: $sans-serif;
		text-transform: uppercase;
		position: absolute;
		top: 15px;
		right: 0;
		color: $black;
		appearance: none;
		border: none;
		font-weight: $bold;
		cursor: pointer;
		font-size: 10px;
		border: none;
	}

	&--button-fill &-button {
		position: absolute;
		top: -0px;
		right: 0;
		background: $black;
		color: $white;
		padding: 16px;
		border: none;
	}
}
@charset 'utf-8';

/*
	good reads about conventions, architecture, naming... Please add to the list
	This will probably be replaced by a RTFM link when the page exists :D

	http://davidtheclark.com/scss-lint-styleguide/
	https://sass-guidelin.es/
	http://basscss.com/
	https://github.com/moklick/frontend-stuff
*/

@import 'settings/variables';
@import 'vendor/bootstrap';

// Settings
@import 'settings/normalize',
		'settings/reset',
		'settings/easings',
		'settings/mixins',
		'settings/fonts',
		'settings/typography',
		'settings/input',
		'settings/checkbox',
		'settings/icons',
		'settings/popins',
		'settings/buttons',
		'settings/select';

// Utilities
@import 'utilities/helpers',
		'utilities/spacings',
		'utilities/colors',
		'utilities/boxes';

// Base
@import 'base/base',
		'base/fonts',
		'base/cms',
		'base/forms';

// Components
@import 'components/folio',
		'components/lines',
		'components/square';

// Pages
// @import 'pages/account';

@import 'shame';

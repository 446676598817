.square {
	height: 15px;
	width: 15px;
	display: inline-block;
	position: relative;
	bottom: 60px;
	right: 10px;

	@include mq( $until: desktop ) {
		height:10px;
		width:10px;
		bottom: 40px;
	}

	@include mq( $until: tablet ) {
		bottom:25px;
		right:5px;
	}

	&-list {
		li {
			margin-bottom: 45px;

			@include mq( $until: desktop ) {
				margin-bottom: 30px;
			}

		}

		&__before{
			background-color: $pink;
			height: 10px;
			width: 10px;
			opacity: 1;
			display: block;
			float: left;
			position: relative;
			margin-right: 10px;
		}
	}
}




.line {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    cursor: default;

		@include mq( $until: tablet ) {
			display: none;
		}
}
.line-1{ 	left: 50px;}
.line-2{	left: calc(25px + 100% / 4);}
.line-3{	left: calc(100% / 2);}
.line-4{	left: calc(-25px + 100% / 4 + 100% / 2);}
.line-5{	left: calc(100% - 50px);}
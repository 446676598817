.button {
	font-family: $sans-serif;
	font-size: 11px;
	color: $white;
	padding: 17px 100px;
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	transition: color .3s $ease-in-out-quad;
	z-index: 0;
	letter-spacing: 2.3px;
	border: solid 1px $black;
	font-family: $sans-serif;
	text-align: center;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $black;
		opacity: 1;
		transition: opacity .3s $ease-in-out-quad;
		z-index: -1;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: $white;
		opacity: 0;
		transition: opacity .3s $ease-in-out-quad;
		z-index: -1;
	}

	span {
		position: relative;
	}

	&:hover,
	&:active{
		color: $black;

		&:before {
			opacity: 0;
		}

		&:after {
			opacity: 1;
		}
	}

	&:focus{
		outline: none;
	}

	&--ghost {
		color: $black;

		&:before {
			content: none;
		}

		&:after {
			background-color: $black;
		}


		&:hover,
		&:active{
			color: $white;
		}
	}

	&--white {
		color: $black;

		&:before {
			background-color: $white;
		}

		&:after {
			background-color: $black;
		}


		&:hover,
		&:active{
			color: $white;
		}
	}

	&--grey {
		color: $black;
		border: none;

		&:before {
			background-color: $grey;
		}

		&:after {
			background-color: $black;
		}


		&:hover,
		&:active{
			color: $white;
		}
	}

	&--small {
		padding: 17px 50px;
	}

	&--no-pad {
		padding: 17px 0;
	}
}

.has-icon{
	span{
		display: inline-block;
		vertical-align: middle;
	}
	.icon{
		vertical-align: middle;
		position: relative;
		z-index: 1;
		margin-right: .5em;
	}
}

.link {
	display: inline-block;
	position: relative;
	font-family: $sans-serif;

	&:before {
		content: '';
		position: absolute;
		top: calc( 100% + 5px );
		left: 0;
		width: 100%;
		height: 1px;
		background-color: $black;
		text-transform: uppercase;
		transition: transform .25s $ease-in-out-quad;
		transform: scaleX(1) translateZ(0);
	}

	&:hover {
		&:before {
			transform: scaleX(.7) translateZ(0);
		}
	}

	&.u-white {
		&:before {
			background-color: $white;
		}
	}

	&--small {
		&:before {
			top: 100%;
			transition: none;
		}

		&:hover {
			&:before {
				transform: none;
			}
		}
	}

	&--no-border {
		opacity: 1;
		transition: opacity .3s $ease-out-quad;

		&:before {
			content: none;
		}

		&:hover {
			opacity: .5;
		}
	}
}
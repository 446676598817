.thumbnail{
	position: relative;

	.thumbnail-cover{
		opacity: .8;
		top: 0px;
		position: absolute;
	}


	&__base {
		&:hover {
			opacity: 0;
		}
	}

	&__hover {
		opacity: 0;
		transition: opacity .2s $ease-in-out-quad;
		&:hover {
			opacity: 1;
		}
	}

}

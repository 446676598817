.icon {
	display: inline-block;
	vertical-align: baseline;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
	width: 1em;
	height: 1em;
	transition: all .3s ease;


	// sizes
	&.icon--xs{
			width: 10px;
			height: 10px;
	}
	&.icon--s{
			width: 12px;
			height: 12px;
	}
	&.icon--m{
			width: 16px;
			height: 16px;
	}
	&.icon--l{
			width: 25px;
			height: 25px;
	}
	&.icon--xl{
			width: 32px;
			height: 32px;
	}
	svg path,
	svg rect,
	svg circle,{
			fill: inherit;
	}
}